.container {
  padding: 1rem;
}

.header {
  margin-bottom: 0rem;
}

.title {
  font-size: 1.25rem;
  font-weight: 600;
  color: #1a1c23;
  padding-bottom: 0.5rem;
  border-bottom: 2px solid #e2e8f0;
}

.form {
  background-color: white;
  border-radius: 0.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.inputContainer {
  padding: 1.5rem;
}

.formGroup {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.formGroup label {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: #4a5568;
  font-size: 0.875rem;
  font-weight: 500;
}

.inputIcon {
  color: #718096;
}

.formGroup input {
  width: 100%;
  padding: 0.625rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.375rem;
  font-size: 0.875rem;
  transition: all 0.2s;
}

.formGroup input:focus {
  border-color: #3182ce;
  outline: none;
  box-shadow: 0 0 0 3px rgba(49, 130, 206, 0.1);
}

.formGroup input::placeholder {
  color: #a0aec0;
}

.button {
  width: 100%;
  padding: 0.75rem;
  background-color: #3182ce;
  color: white;
  border: none;
  border-radius: 0 0 0.5rem 0.5rem;
  font-size: 0.875rem;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s;
}

.button:hover:not(:disabled) {
  background-color: #2c5282;
}

.button:disabled {
  background-color: #a0aec0;
  cursor: not-allowed;
}

.errorMessage {
  color: #e53e3e;
  font-size: 0.875rem;
  margin-top: 0.5rem;
  padding: 0 1.5rem;
}

@media (max-width: 640px) {
  .container {
    padding: 0.5rem;
  }
}


  
  h2 {
    color: #000000;


  }
  
  form {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  label {
    margin: 10px;
    color: #363636;
  }
  
  input {
    padding: 8px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 3px;
  }
  
  button {
    background-color: #4caf50;
    color: white;
    padding: 10px;
    border: none;
    border-radius: 3px;
    cursor: pointer;
  }
  
  button:hover {
    background-color: #45a049;
  }
  
  .error-message {
    color: red;
    margin-top: 10px;
  }
  