.container {
  padding: 1rem;
}

.title {
  font-size: 1.25rem;
  font-weight: 600;
  color: #1a1c23;
  margin-bottom: 1.5rem;
  padding-bottom: 0.5rem;
  border-bottom: 2px solid #e2e8f0;
}

.employeeList {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1.5rem;
  list-style: none;
  padding: 0;
}

.employeeItem {
  background-color: white;
  border-radius: 0.5rem;
  padding: 1.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  transition: transform 0.2s, box-shadow 0.2s;
  position: relative;
}

.employeeItem:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.employeeItem span {
  display: block;
  color: #4a5568;
  margin-bottom: 1rem;
  font-size: 0.875rem;
  line-height: 1.5;
}

.fotoN {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 0.375rem;
  margin-bottom: 1rem;
}

.employeeItem form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.employeeItem form label {
  color: #4a5568;
  font-size: 0.875rem;
  margin-bottom: 0.25rem;
}

.employeeItem form input {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.375rem;
  font-size: 0.875rem;
}

.employeeItem form input:focus {
  border-color: #3182ce;
  outline: none;
  box-shadow: 0 0 0 3px rgba(49, 130, 206, 0.1);
}

.editButton {
  background-color: #3182ce;
  color: white;
  border: none;
  border-radius: 0.375rem;
  padding: 0.5rem 1rem;
  font-size: 0.875rem;
  cursor: pointer;
  transition: background-color 0.2s;
  margin-right: 0.5rem;
}

.editButton:hover {
  background-color: #2c5282;
}

.deleteButton {
  background-color: #e53e3e;
  color: white;
  border: none;
  border-radius: 0.375rem;
  padding: 0.5rem 1rem;
  font-size: 0.875rem;
  cursor: pointer;
  transition: background-color 0.2s;
}

.deleteButton:hover {
  background-color: #c53030;
}

.employeeItem form button[type="submit"],
.employeeItem form button[type="button"] {
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 0.375rem;
  font-size: 0.875rem;
  cursor: pointer;
  transition: background-color 0.2s;
}

.employeeItem form button[type="submit"] {
  background-color: #3182ce;
  color: white;
  margin-right: 0.5rem;
}

.employeeItem form button[type="submit"]:hover {
  background-color: #2c5282;
}

.employeeItem form button[type="button"] {
  background-color: #e2e8f0;
  color: #4a5568;
}

.employeeItem form button[type="button"]:hover {
  background-color: #cbd5e0;
}