.container {
  padding: 1rem;
}

.header {
  margin-bottom: 2rem;
}

.title {
  font-size: 1.25rem;
  font-weight: 600;
  color: #1a1c23;
  padding-bottom: 0.5rem;
  border-bottom: 2px solid #e2e8f0;
}

.form {
  background-color: white;
  border-radius: 0.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.inputContainer {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1.5rem;
  padding: 1.5rem;
}

.formGroup {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.formGroup label {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: #4a5568;
  font-size: 0.875rem;
  font-weight: 500;
}

.inputIcon {
  color: #718096;
}

.formGroup input,
.formGroup select {
  width: 100%;
  padding: 0.625rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.375rem;
  font-size: 0.875rem;
  transition: all 0.2s;
  background-color: white;
}

.formGroup input:focus,
.formGroup select:focus {
  border-color: #3182ce;
  outline: none;
  box-shadow: 0 0 0 3px rgba(49, 130, 206, 0.1);
}

.formGroup input::placeholder {
  color: #a0aec0;
}

.fileInput {
  padding: 0.5rem 0 !important;
  border: none !important;
  background-color: transparent !important;
}

.imagePreview {
  padding: 1.5rem;
  border-top: 1px solid #e2e8f0;
}

.imagePreview h2 {
  font-size: 1rem;
  font-weight: 500;
  color: #1a1c23;
  margin-bottom: 1rem;
}

.imageGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 1rem;
}

.previewImage {
  width: 100%;
  height: 150px;
  object-fit: cover;
  border-radius: 0.375rem;
  border: 1px solid #e2e8f0;
}

.button {
  width: 100%;
  padding: 0.75rem;
  background-color: #3182ce;
  color: white;
  border: none;
  border-radius: 0 0 0.5rem 0.5rem;
  font-size: 0.875rem;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s;
}

.button:hover {
  background-color: #2c5282;
}

.alert {
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  background-color: #48bb78;
  color: white;
  padding: 1rem;
  border-radius: 0.375rem;
  animation: slideIn 0.3s ease-out;
}

@keyframes slideIn {
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@media (max-width: 640px) {
  .inputContainer {
    grid-template-columns: 1fr;
  }

  .alert {
    left: 1rem;
    right: 1rem;
    text-align: center;
  }
}

