.biggerContainer{
  background-color: black;
}

.layout {
  display: flex;
  min-height: 100vh;
  background-color: black;
}

.sidebar {
  width: 72px;
  background-color: #000;
  padding: 1.5rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  height: 100%;
  z-index: 50;
}

.logo {
  color: white;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 2rem;
}

.nav {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
  align-items: center;
}

.navButton {
  width: 40px;
  height: 40px;
  border: none;
  background: transparent;
  color: #666;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.2s;
}

.navButton:hover {
  background-color: rgba(255, 255, 255, 0.1);
  color: white;
}

.navButton.active {
  background-color: rgba(255, 255, 255, 0.1);
  color: white;
}

.logoutButton {
  width: 40px;
  height: 40px;
  border: none;
  background: transparent;
  color: #666;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-top: auto;
  transition: all 0.2s;
}

.logoutButton:hover {
  background-color: rgba(255, 255, 255, 0.1);
  color: white;
}

.main {
  flex: 1;
  margin-left: 72px;
  padding: 2rem;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
}

.headerLeft {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.greeting {
  font-size: 1.5rem;
  font-weight: 600;
  color: white;
  margin: 0;
}

.subgreeting {
  color: #666;
  margin: 0;
}

.headerRight {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.iconButton {
  width: 40px;
  height: 40px;
  border: none;
  background-color: white;
  color: #666;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.2s;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.iconButton:hover {
  background-color: #f8f9fa;
}

.avatar {
  width: 100%;
  height: 40px;
  padding: 0px 10px;
  background-color: white;
  color: black;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
}

.content {
  background-color: white;
  border-radius: 12px;
  padding: 2rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

/* Login styles */
.mainFormContainer {
  min-height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: black;
  padding: 1rem;
}

.loginForm {
  background-color: white;
  padding: 2rem;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
  width: 100%;
  max-width: 400px;
}

.loginTitle {
  font-size: 1.5rem;
  font-weight: 600;
  color: #000;
  margin-bottom: 2rem;
  text-align: center;
}

.inputGroup {
  margin-bottom: 1.5rem;
}

.input {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #eee;
  border-radius: 8px;
  font-size: 1rem;
  transition: all 0.2s;
}

.input:focus {
  outline: none;
  border-color: #000;
}

.loginButton {
  width: 100%;
  padding: 0.75rem;
  background-color: #000;
  color: white;
  border: none;
  border-radius: 8px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s;
  margin-bottom: 1rem;
}

.loginButton:hover {
  background-color: #333;
}

.forgotPasswordButton {
  width: 100%;
  padding: 0.75rem;
  background-color: transparent;
  color: #666;
  border: none;
  cursor: pointer;
  transition: all 0.2s;
}

.forgotPasswordButton:hover {
  color: #000;
}

.errorMessage {
  color: #dc2626;
  text-align: center;
  margin-top: 1rem;
  font-size: 0.875rem;
}

