.listaDeObras {
  padding: 1rem;
}

.obras-section {
  margin-bottom: 2rem;
}

.subtitle {
  font-size: 1.25rem;
  font-weight: 600;
  color: #1a1c23;
  margin-bottom: 1rem;
  padding-bottom: 0.5rem;
  border-bottom: 2px solid #e2e8f0;
}

.obras-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1.5rem;
  list-style: none;
  padding: 0;
}

.obras-list-item {
  background-color: white;
  border-radius: 0.5rem;
  padding: 1.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  transition: transform 0.2s, box-shadow 0.2s;
}

.obras-list-item:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.obras-list-item h3 {
  font-size: 1.125rem;
  font-weight: 600;
  color: #1a1c23;
  margin-bottom: 1rem;
}

.obras-list-item p {
  color: #4a5568;
  margin-bottom: 0.5rem;
  font-size: 0.875rem;
}

.imagenes {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 0.375rem;
  margin-bottom: 1rem;
}

.editInput {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.375rem;
  margin-bottom: 0.5rem;
  font-size: 0.875rem;
}

.editInput:focus {
  border-color: #3182ce;
  outline: none;
  box-shadow: 0 0 0 3px rgba(49, 130, 206, 0.1);
}

.editButton, .saveButton {
  background-color: #3182ce;
  color: white;
  border: none;
  border-radius: 0.375rem;
  padding: 0.5rem 1rem;
  font-size: 0.875rem;
  cursor: pointer;
  transition: background-color 0.2s;
  margin-right: 0.5rem;
}

.editButton:hover, .saveButton:hover {
  background-color: #2c5282;
}

.deleteButton {
  background-color: #e53e3e;
  color: white;
  border: none;
  border-radius: 0.375rem;
  padding: 0.5rem 1rem;
  font-size: 0.875rem;
  cursor: pointer;
  transition: background-color 0.2s;
}

.deleteButton:hover {
  background-color: #c53030;
}

.loading-message {
  text-align: center;
  color: #4a5568;
  font-size: 1rem;
  padding: 2rem;
}